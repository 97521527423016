<template>
  <div>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { PayPal } from "../utils/constants";

export default {
  name: "PayPalButton",

  data() {
    return {
      planId: PayPal.planID,
    };
  },
  async mounted() {
    // await this.checkSubscriptionStatus(); // Check if user is already subscribed
    this.loadPayPalScript();
  },
  beforeUnmount() {
    document.getElementById("paypal-button-container").innerHTML = "";
  },
  methods: {
    async setLogDate() {
      const user = await Auth.currentAuthenticatedUser();

      // Safely parse the subLog array or initialize it as an empty array
      const subLogArr = JSON.parse(localStorage.getItem("subLog")) || [];

      // Prepare new log data
      const sublogData = {
        timestamp: new Date()
          .toISOString()
          .replace(/[-:.TZ]/g, "")
          .slice(0, 14), // Format: YYYYMMDDHHMMSS
        subState: user.attributes["custom:pro"] == "active" ? "ok" : "failed",
        pro: user.attributes["custom:pro"],
        subID: user.attributes["custom:subscriptionID"],
      };

      // Add new data to the array
      subLogArr.push(sublogData);
      // Save the updated array back to localStorage
      localStorage.setItem("subLog", JSON.stringify(subLogArr));
    },
    // Dynamically load the PayPal SDK with client-id from environment variables
    loadPayPalScript() {
      if (document.querySelector('script[src*="paypal.com/sdk/js"]')) {
        this.renderPayPalButton();
        return;
      }
      const scriptTag = document.createElement("script");
      scriptTag.src = `https://www.paypal.com/sdk/js?client-id=${PayPal.subClientId}&vault=true&intent=subscription`;
      scriptTag.onload = () => {
        this.renderPayPalButton();
      };
      scriptTag.onerror = () => {
        console.error("Failed to load PayPal script.");
      };

      document.head.appendChild(scriptTag);
    },

    renderPayPalButton() {
      if (typeof window.paypal === "undefined") {
        console.error("PayPal SDK not loaded.");
        return;
      }
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "subscribe", // Subscription-specific label
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: this.planId, // Pass the plan ID from props
            });
          },
          onApprove: async (subscriptionData, actions) => {
            // Handle successful subscription
            // console.log("Subscription completed with ID: ", data);
            const { facilitatorAccessToken, subscriptionID } = subscriptionData;
            // Set PayPal Customer Number in Cognito
            const user = await Auth.currentAuthenticatedUser();

            // console.log("user props", user);
            // console.log("sub data after approve", subscriptionData);

            const attributes = {
              "custom:subscriptionID": subscriptionID, // Storing subscription ID as PayPal Customer Number
              "custom:pro": "active",
              // "custom:lastPayment": "active",
            };

            await Auth.updateUserAttributes(user, attributes);
            this.$store.dispatch("setSubStatus", "active");
            this.$store.dispatch("setSubID", subscriptionID);
            this.$store.dispatch("setUserToken", facilitatorAccessToken);
            this.$store.dispatch("setDeleteDialog", {});
            this.$store.dispatch("addToast", {
              severity: "success",
              life: 3000,
              summary: "Subscribed",
              detail: "You successfully subscribed to pro plan.",
            });
            this.setLogDate();
          },
          onError: (err) => {
            // Handle errors during subscription creation
            console.error("PayPal Subscription Error:", err);
            this.$store.dispatch("setSubStatus", "inactive");
            this.$store.dispatch("setSubID", "");
            this.$store.dispatch("setDeleteDialog", {});
            this.$store.dispatch("addToast", {
              severity: "error",
              life: 3000,
              summary: "Subscription failed",
              detail: "Subscription failed. Please try again.",
            });
            this.setLogDate();
          },
        })
        .render("#paypal-button-container");
    },
  },
};
</script>
